<template>
   <v-row justify="center">
      <v-col cols="12" md="8">
         <v-card>
            <v-card-title>
               <div class="display-2 font-weight-light">
                  Edit Profile
               </div>
            </v-card-title>
            <v-card-subtitle>
               Complete your profile
            </v-card-subtitle>
            <v-divider class="mx-3"/>
            <v-card-text>
               <v-form>
                  <v-row>
                     <v-col cols="12" md="6">
                        <v-text-field
                           label="UUID"
                           disabled
                           :value="fm.uid"
                        />
                     </v-col>
                     <v-col cols="12" md="6">
                        <v-text-field label="Email Address" class="purple-input" v-model="fm.email"/>
                     </v-col>
                     <v-col cols="12" md="6">
                        <v-text-field
                           class="purple-input"
                           label="User Name"
                           v-model="fm.displayName"
                        />
                     </v-col>
                     <v-col cols="12" md="6">
                        <v-text-field
                           label="Phone"
                           class="purple-input"
                           v-model="fm.phoneNumber"
                        />
                     </v-col>
                     <v-col cols="12">
                        <v-text-field
                           label="Photo URL"
                           class="purple-input"
                           v-model="fm.photoURL"
                        />
                     </v-col>
                     <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0" :loading="on_saving" @click="save">
                           Update Profile
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>
            <v-divider class="mx-3"/>
            <v-card-text>
               <v-row v-if="show_pwd_fm">
                  <v-col cols="12" md="4">
                     <v-text-field
                        label="New Password"
                        type="password"
                     />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field
                        label="Retype Password"
                        type="password"
                     />
                  </v-col>
               </v-row>
               <div class="d-flex justify-space-between">
                  <v-btn small outlined color="error" v-if="show_pwd_fm" @click="show_pwd_fm=false">
                     cancel
                  </v-btn>
                  <v-btn small :outlined="show_pwd_fm" text color="success" @click="show_pwd_fm=!show_pwd_fm">
                     Change Password
                  </v-btn>
               </div>
            </v-card-text>
         </v-card>
      </v-col>
 
      <v-col cols="12" md="4">
         <v-card>
            <v-card-text class="text-center">
               <v-avatar size="150" class="mb-3">
                  <img :src="fm.photoURL">
               </v-avatar>
               <h6 class="text-h5 mb-1 grey--text">
                  {{fm.email}}
               </h6>
   
               <h4 class="display-2 font-weight-light mb-3 black--text">
                  {{fm.displayName}}
               </h4>
   
               <p class="font-weight-light grey--text">
                  Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
               </p>
            </v-card-text>
         </v-card>
      </v-col>
   </v-row>
</template>
 
<script>
export default {
   mounted() {
      this.fm = {...this.$store.state.user}
      console.log('on mount fro', this.$store.state.user)
   },
   data() {
      return {
         on_saving: false,
         show_pwd_fm: false,
         fm: {
            displayName: '',
            email: '',
            password: '',
            password_confirmation: ''
         }
      }
   },
   watch: {
      '$store.state.user': function (val) {
         this.fm = {...val}
      }
   },
   methods: {
      save() {
         this.on_saving = true
         this.$store.dispatch('fb/update', this.fm).then(() => {
            this.on_saving = false
         })
      }
   }
}
</script>